import { Component, Input, SimpleChanges } from '@angular/core';
import { Chart } from 'chart.js/auto';
import { IGenericServiceResponse } from '../../../../interfaces/IServiceResponse';
import { environment } from '../../../../../environments/environment';
import { IDashboardChart } from '../../../../interfaces/Dashboard/IDashboardChart';
import { HttpClient } from '@angular/common/http';
import { IDateTimeRange } from 'src/app/interfaces/Dashboard/IDateTimeRange';
import { TranslateService } from '@ngx-translate/core';
import { DashboardService } from 'src/app/services/dashboard.service';

@Component({
  selector: 'app-dashboard-average-registration-status-times',
  templateUrl: './dashboard-average-registration-status-times.component.html',
  styleUrls: ['./dashboard-average-registration-status-times.component.css'],
})
export class DashboardAverageRegistrationStatusTimesComponent {
  @Input() dateTimeRange: IDateTimeRange | null = null; // Input to receive the selected date range

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private readonly dashboardService: DashboardService
  ) {}

  public averageRegistrationStatusTimes: IDashboardChart | null = null;

  ngAfterViewInit(): void {
    this.fetchData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dateTimeRange'] && !changes['dateTimeRange'].firstChange) {
      this.fetchData(); // Fetch data whenever dateTimeRange changes
    }
  }

  BarChart(): void {
    const datasets = this.averageRegistrationStatusTimes?.dataSet || [];

    const data = {
      labels: [''],
      datasets: datasets.map((dataset, index) => ({
        label: this.translate.instant(dataset.label || ''),
        data: dataset.data,
        backgroundColor: [dataset.backgroundColor],
        borderColor: [dataset.backgroundColor],
        borderRadius: 10,
        boarderWidth: 1,
      })),
    };

    new Chart('averageRegistrationStatusTimes', {
      type: 'bar',
      data: data,
      options: {
        indexAxis: 'x',
        elements: {
          bar: {
            borderWidth: 1,
            borderRadius: 10,
          },
        },
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: this.translate.instant(
              'Average Application Processing Time (days)'
            ),
          },
        },
      },
    });
  }

  setBuffer(data: string[]): number {
    // Convert strings to numeric values
    const numericData = data.map((str) => parseFloat(str));

    // Calculate the maximum value in the numeric dataset
    const maxDataValue = Math.max(...numericData);

    // Set a buffer value to make the maximum value of the chart slightly higher
    const buffer = 3; // You can adjust this value as needed

    // Calculate the maximum value for the chart's y-axis
    const maxYAxisValue = maxDataValue + buffer;

    return maxYAxisValue;
  }

  fetchData(): void {
    let chartStatus = Chart.getChart('averageRegistrationStatusTimes');
    if (chartStatus != undefined) {
      chartStatus.destroy();
    }

    this.dashboardService
      .getAverageRegistrationStatusTimes()
      .subscribe((data) => {
        this.averageRegistrationStatusTimes = data.data;
        this.BarChart();
      });
  }
}
