import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  AbstractControl,
  FormControl,
} from '@angular/forms';
import { ICoding } from 'src/app/interfaces/ICoding';
import { MatDialog } from '@angular/material/dialog';
import { AddPatientService } from '../../../../services/addpatient.service';
import {
  CountryISO,
  PhoneNumberFormat,
  SearchCountryField,
} from 'ngx-intl-tel-input-gg';

@Component({
  selector: 'app-emergency-contacts',
  templateUrl: './emergency-contacts.component.html',
  styleUrls: ['./emergency-contacts.component.css'],
})
export class EmergencyContactsComponent implements OnInit {
  @Input() formSubmitted?: boolean = false;
  @Input() countriesList: ICoding[] = [];
  @Input() closestRelativesList: ICoding[] = [];

  emergencyContactsForm: FormGroup;
  isCountryCyprus: boolean = false;

  // Define the Cyprus cities
  cyprusCities: string[] = [
    'Nicosia',
    'Limassol',
    'Larnaca',
    'Paphos',
    'Famagusta',
    'Kyrenia',
  ];

  selectedDoctors: any[] = [];
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.Cyprus, CountryISO.Greece];

  constructor(
    private readonly formBuilder: FormBuilder,
    private dialog: MatDialog,
    private addPatientService: AddPatientService
  ) {
    this.emergencyContactsForm = this.formBuilder.group({
      contacts: this.formBuilder.array([]),
    });
  }

  ngOnInit() {
    this.addEmergencyContact();
  }

  get contacts(): FormArray {
    return this.emergencyContactsForm.get('contacts') as FormArray;
  }

  // Access a specific contact FormGroup
  getContactFormGroup(index: number): AbstractControl {
    return this.contacts.at(index);
  }

  addEmergencyContact() {
    const contactForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      identifierType: [''],
      identifier: [''],
      identifierCountry: [''],
      phoneNumber: ['', Validators.required],
      occupation: [''],
      street: [undefined],
      streetNumber: [undefined],
      apartmentNumber: [undefined],
      town: [undefined],
      postcode: [undefined],
      district: [undefined],
      country: [undefined],
      closestRelativeId: [undefined],
      closestRelativeOther: [''], // Free text input for "Other"
      email: ['', Validators.email],
    });

    this.contacts.push(contactForm);
    this.updateValidators();
  }

  removeEmergencyContact(index: number) {
    this.contacts.removeAt(index);
    this.updateValidators();
  }

  updateValidators() {
    this.contacts.controls.forEach((contact, index) => {
      if (index === 0) {
        contact.get('identifierType')?.setValidators(Validators.required);
        contact.get('identifier')?.setValidators(Validators.required);
      } else {
        contact.get('identifierType')?.clearValidators();
        contact.get('identifier')?.clearValidators();
      }
      contact.get('identifierType')?.updateValueAndValidity();
      contact.get('identifier')?.updateValueAndValidity();
    });
  }

  onIdentifierTypeChange(type: string, index: number) {
    const contact = this.contacts.at(index);
    if (type === 'arc') {
      contact.get('identifierCountry')?.setValue('');
      contact.get('identifierCountry')?.clearValidators();
    } else {
      contact.get('identifierCountry')?.setValidators(Validators.required);
    }
    contact.get('identifierCountry')?.updateValueAndValidity();
  }

  // Method to handle place of birth change
  onCountryChange(placeId: number): void {
    this.isCountryCyprus = placeId === 55; // Cyprus id is 55
  }

  getPhoneNumberControl(i: number): FormControl<string | null> {
    return (
      (this.getContactFormGroup(i).get('phoneNumber') as FormControl<
        string | null
      >) || new FormControl<string | null>(null)
    );
  }
}
