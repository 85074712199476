import { Component, ViewChild } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { Subject, takeUntil } from 'rxjs';
import { LaboratoryFileService } from 'src/app/services/laboratoryFile.service';
import { IAddLaboratoryFile } from 'src/app/interfaces/LaboratoryFile/IAddLaboratoryFile';
import { ILaboratoryFileDropdown } from 'src/app/interfaces/LaboratoryFile/ILaboratoryFileDropdown';

@Component({
  selector: 'app-laboratory-file-add',
  templateUrl: './laboratory-file-add.component.html',
  styleUrls: ['./laboratory-file-add.component.css'],
})
export class LaboratoryFileAddComponent {
  private destroy$ = new Subject<void>();

  laboratoryDropdown: ILaboratoryFileDropdown | undefined;

  fileObj?: File;

  isVisible = false;
  isLoading = false;

  isEdit = false;
  editProblemsId?: number;

  laboratorySubmitted = false;

  @ViewChild('modalRef', { static: true }) modalRef!: NzModalRef; // Reference to the modal

  laboratoryForm: FormGroup = new FormGroup({});

  constructor(
    private readonly laboratoryService: LaboratoryFileService,
    private readonly formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.isEdit = false;

    this.laboratoryService.getLaboratoryDropdown().subscribe({
      next: (pro) => {
        this.laboratoryDropdown = pro?.data;
        this.isLoading = false;
      },
      error: (err) => {},
    });

    this.laboratoryForm = this.formBuilder.group({
      categoryValue: [null, Validators.required],
      examTitleValue: [null, Validators.required],
      dateValue: [null, Validators.required],
      descriptionValue: [null, Validators.required],
      fileValue: [null, Validators.required],
    });

    this.laboratoryService.laboratoryEditSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        // GET DATA FIELDS AND ASSIGN DEFAULT VALUES TO FORM
        if (data) {
          this.isEdit = true;
          this.editProblemsId = data?.id;

          this.laboratoryForm.patchValue({
            categoryValue: data?.categoryId,
            examTitleValue: data?.examTitle,
            dateValue: data?.date,
            descriptionValue: data?.description,
          });
        } else {
          this.isEdit = false;
          this.editProblemsId = undefined;

          this?.laboratoryForm.reset();
        }
      });

    this.laboratoryService.laboratoryModalSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((showModal) => {
        this.isVisible = showModal;
      });
  }

  ngOnDestroy() {
    // Unsubscribe from observables
    this.destroy$.next();
    this.destroy$.complete();

    this?.modalRef.destroy();
  }

  getCurrentDate() {
    return new Date().toISOString().split('T')[0];
  }

  handleCancel() {
    this.laboratoryForm.reset();

    this.laboratoryService.laboratoryModalSub.next(false);

    this.laboratorySubmitted = false;

    this.isLoading = false;

    this.modalRef.destroy();
  }

  submitForm() {
    this.isLoading = true;
    this.laboratorySubmitted = true;

    if (this.fileObj && this.laboratoryForm.valid) {
      const laboratoryObj: IAddLaboratoryFile = {};

      laboratoryObj.id = this.editProblemsId;
      laboratoryObj.categoryId = this.laboratoryForm.value?.categoryValue;
      laboratoryObj.examTitleId = this.laboratoryForm.value?.examTitleValue;
      laboratoryObj.date = this.laboratoryForm.value?.dateValue;
      laboratoryObj.description = this.laboratoryForm.value?.descriptionValue;
      laboratoryObj.file = this.fileObj;

      if (this.isEdit) {
        this.laboratoryService.editLaboratory(laboratoryObj).subscribe({
          next: () => {
            Swal.fire({
              text: 'Laboratory edited successfully!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#0d9488',
              timer: 3000,
            });

            this.laboratoryService.getData();

            this.handleCancel();
          },
          error: (err) => {
            Swal.fire({
              text: 'Unable to edit Laboratory!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#ff6969',
              timer: 3000,
            });

            this.handleCancel();
          },
        });
      } else {
        this.laboratoryService.addLaboratory(laboratoryObj).subscribe({
          next: () => {
            Swal.fire({
              text: 'Laboratory added successfully!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#0d9488',
              timer: 3000,
            });

            this.laboratoryService.getData();

            this.handleCancel();
          },
          error: (err) => {
            Swal.fire({
              text: 'Unable to add Laboratory!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#ff6969',
              timer: 3000,
            });

            this.handleCancel();
          },
        });
      }
    } else {
      this.isLoading = false;
    }
  }

  onFileSelected(event: any) {
    const file: File | null = event.target.files[0];

    if (file) {
      this.fileObj = file;
      this.laboratoryForm.get('fileValue')?.setValue(file?.name);
      this.laboratoryForm.get('fileValue')?.setErrors(null);
    } else {
      this.fileObj = undefined;
      this.laboratoryForm.get('fileValue')?.setValue(undefined);
      this.laboratoryForm?.get('fileValue')?.setErrors({ invalid: true });
    }
  }
}
