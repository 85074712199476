<div fxLayout="row" class="allergy-expanded">
    <div fxLayout="col" fxFlex="100" class="allergy-details">
      <nz-layout>
        <nz-header>{{"Details" | translate}}</nz-header>
  
        <nz-content>
          <!-- No. of comorbidities -->
          <div fxLayout="row" class="detail-row" *ngIf="item.noComorbidities">
            <div fxLayout="col" fxFlex="50" class="detail-label">{{'No. of comorbidities' | translate}}</div>
            <div fxLayout="col" fxFlex="50" class="detail-info">{{item.noComorbidities || "-"}}</div>
          </div>
  
          <!-- Median (IQR) -->
          <!-- <div fxLayout="row" class="detail-row" *ngIf="item.iqr">
            <div fxLayout="col" fxFlex="50" class="detail-label">{{'Median (IQR)' | translate}}</div>
            <div fxLayout="col" fxFlex="50" class="detail-info">{{item.iqr || "-"}}</div>
          </div> -->
  
          <!-- Any comorbidity, n (%) -->
          <div fxLayout="row" class="detail-row" *ngIf="item.anyComorbidity">
            <div fxLayout="col" fxFlex="50" class="detail-label">{{'Any comorbidity, n (%)' | translate}}</div>
            <div fxLayout="col" fxFlex="50" class="detail-info">{{item.anyComorbidity || "-"}}</div>
          </div>
  
          <!-- Respiratory disease, n(%) -->
          <div fxLayout="row" class="detail-row" *ngIf="item.respiratoryDisease">
            <div fxLayout="col" fxFlex="50" class="detail-label">{{'Respiratory disease, n(%)' | translate}}</div>
            <div fxLayout="col" fxFlex="50" class="detail-info">{{item.respiratoryDisease || "-"}}</div>
          </div>
  
          <!-- Hypertension, n (%) -->
          <div fxLayout="row" class="detail-row" *ngIf="item.hypertension">
            <div fxLayout="col" fxFlex="50" class="detail-label">{{'Hypertension, n (%)' | translate}}</div>
            <div fxLayout="col" fxFlex="50" class="detail-info">{{item.hypertension || "-"}}</div>
          </div>
  
          <!-- COPD, n (%) -->
          <div fxLayout="row" class="detail-row" *ngIf="item.copd">
            <div fxLayout="col" fxFlex="50" class="detail-label">{{'COPD, n (%)' | translate}}</div>
            <div fxLayout="col" fxFlex="50" class="detail-info">{{item.copd || "-"}}</div>
          </div>
  
          <!-- Heart Disease, n(%) -->
          <div fxLayout="row" class="detail-row" *ngIf="item.heartDisease">
            <div fxLayout="col" fxFlex="50" class="detail-label">{{'Heart Disease, n(%)' | translate}}</div>
            <div fxLayout="col" fxFlex="50" class="detail-info">{{item.heartDisease || "-"}}</div>
          </div>
  
          <!-- Diabetes mellitus type II, n (%) -->
          <div fxLayout="row" class="detail-row" *ngIf="item.diabetesMellitus">
            <div fxLayout="col" fxFlex="50" class="detail-label">{{'Diabetes mellitus type II, n (%)' | translate}}</div>
            <div fxLayout="col" fxFlex="50" class="detail-info">{{item.diabetesMellitus || "-"}}</div>
          </div>
  
          <!-- Malignancy, n (%) -->
          <div fxLayout="row" class="detail-row" *ngIf="item.malignancy">
            <div fxLayout="col" fxFlex="50" class="detail-label">{{'Malignancy, n (%)' | translate}}</div>
            <div fxLayout="col" fxFlex="50" class="detail-info">{{item.malignancy || "-"}}</div>
          </div>
  
          <!-- Stroke, n (%) -->
          <div fxLayout="row" class="detail-row" *ngIf="item.stroke">
            <div fxLayout="col" fxFlex="50" class="detail-label">{{'Stroke, n (%)' | translate}}</div>
            <div fxLayout="col" fxFlex="50" class="detail-info">{{item.stroke || "-"}}</div>
          </div>
  
          <!-- Immunosuppression, n (%) -->
          <div fxLayout="row" class="detail-row" *ngIf="item.immunosuppression">
            <div fxLayout="col" fxFlex="50" class="detail-label">{{'Immunosuppression, n (%)' | translate}}</div>
            <div fxLayout="col" fxFlex="50" class="detail-info">{{item.immunosuppression || "-"}}</div>
          </div>
  
          <!-- No Comorbidities, n(%) -->
          <div fxLayout="row" class="detail-row" *ngIf="item.noComorbidities">
            <div fxLayout="col" fxFlex="50" class="detail-label">{{'No Comorbidities, n(%)' | translate}}</div>
            <div fxLayout="col" fxFlex="50" class="detail-info">{{item.noComorbidities || "-"}}</div>
          </div>
  
        </nz-content>
  
      </nz-layout>
    </div>
  </div>
  