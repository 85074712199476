import { Component } from '@angular/core';
import { Chart } from 'chart.js/auto';
import { InpatientService } from '../../../../services/inpatient.service';
import { IDashboardChart } from '../../../../interfaces/Dashboard/IDashboardChart';
import { TranslateService } from '@ngx-translate/core';
import { IDashboardData } from 'src/app/interfaces/Dashboard/IDashboardData';

@Component({
  selector: 'app-dashboard-admissions-by-older-age',
  templateUrl: './dashboard-admissions-by-older-age.component.html',
  styleUrl: './dashboard-admissions-by-older-age.component.css',
})
export class DashboardAdmissionsByOlderAgeComponent {
  constructor(
    private readonly inPatientService: InpatientService,
    private translate: TranslateService
  ) {}

  ngAfterViewInit(): void {
    this.getData();
  }

  public InPatientOlderAge: IDashboardChart | null = null;

  BarChart(): void {
    const datasets = this.InPatientOlderAge?.dataSet || [];
    const maxYAxisValue = this.setBuffer(this.InPatientOlderAge?.dataSet!);

    const data = {
      labels: [''],
      datasets: datasets.map((dataset, index) => ({
        label: this.translate.instant(dataset.label || ''),
        data: dataset.data,
        backgroundColor: [dataset.backgroundColor],
        borderColor: 'rgba(128, 128, 128, 0.6)',
        borderRadius: 10,
        borderWidth: 1,
      })),
    };

    new Chart('inPatientOlderAge', {
      type: 'bar',
      data: data,
      options: {
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
            max: maxYAxisValue,
            ticks: {
              stepSize: 1,
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: this.translate.instant('Admissions by Age'),
          },
        },
      },
    });
  }

  setBuffer(data: IDashboardData[]): number {
    const numericData =
      data.flatMap(
        (dataSet) => dataSet.data?.map((str) => parseFloat(str) || 0) || [0]
      ) || 0;

    const maxDataValue = Math.max(...numericData);

    const buffer = 3;

    const maxYAxisValue = maxDataValue + buffer;

    return maxYAxisValue;
  }

  getData() {
    this.inPatientService.getDashboardAdmissionsByOlderAge().subscribe({
      next: (response) => {
        this.InPatientOlderAge = response?.data;
        this.BarChart();
      },
      error: (err) => {},
    });
  }
}
