import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { EpisodeOfCareService } from '../services/episodeOfCare.service';
import { PatientService } from '../services/patient.service';

@Injectable({
  providedIn: 'root',
})
export class EocGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly eocService: EpisodeOfCareService,
    private readonly patientService: PatientService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    // Access parameters directly from the ActivatedRouteSnapshot
    const patientValue = next.paramMap.get('patientID');
    const eocValue = next.paramMap.get('eocID');
    const visitValue = next.paramMap.get('visitID');

    if (patientValue && eocValue && visitValue) {
      this.patientService.patientIDsignal.set(patientValue);
      this.eocService.currentEocId.set(eocValue);
      this.eocService.currentVisitId.set(visitValue);
      return true;
    } else {
      this.router.navigate(['/']);
      return false;
    }
  }
}
