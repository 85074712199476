<div class="patients-container">
  <div class="patients-search">
    <div
      fxLayout="column"
      fxLayoutAlign=""
      fxFlex="15"
      class="patients-search-input"
    >
      <input
        matInput
        [placeholder]="'First name' | translate"
        [(ngModel)]="firstName"
        (ngModelChange)="firstNameChanged($event, 'firstName')"
      />
    </div>
    <div
      fxLayout="column"
      fxLayoutAlign=""
      fxFlex="15"
      class="patients-search-input"
    >
      <input
        matInput
        [placeholder]="'Last name' | translate"
        [(ngModel)]="lastName"
        (ngModelChange)="lastNameChanged($event, 'lastName')"
      />
    </div>
    <div
      fxLayout="column"
      fxLayoutAlign=""
      fxFlex="15"
      class="patients-search-input"
    >
      <input
        matInput
        [placeholder]="'Role' | translate"
        [(ngModel)]="role"
        (ngModelChange)="roleChanged($event, 'role')"
      />
    </div>
    <div
      fxLayout="column"
      fxLayoutAlign=""
      fxFlex="15"
      class="patients-search-input"
    >
      <input
        matInput
        [placeholder]="'Email' | translate"
        [(ngModel)]="email"
        (ngModelChange)="emailChanged($event, 'email')"
      />
    </div>
    <div
      fxLayout="column"
      fxLayoutAlign=""
      fxFlex="15"
      class="patients-search-input"
    >
      <input
        matInput
        [placeholder]="'Phone Number' | translate"
        [(ngModel)]="phoneNumber"
        (ngModelChange)="phoneNumberChanged($event, 'phoneNumber')"
      />
    </div>

    <div
      fxLayout="column"
      fxLayoutAlign=""
      fxFlex="25"
      class="patients-search-buttons"
    >
      <div fxLayout="row" fxFlex="100" fxLayoutAlign="end center">
        <div fxLayout="column" fxLayoutAlign="" fxFlex="100">
          <button class="register-button" (click)="registerUser()">
            <i nz-icon nzType="icons:userAdd"></i>
            <div class="btn-text" style="padding-left: 10px">{{ "Register User" | translate }}</div>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="patients-table">
    <mat-table [dataSource]="data" class="mat-elevation-z8">
      <ng-container matColumnDef="firstName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "First name" | translate }}
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let element">
          {{ element.firstName || "-" }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="lastName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "Last name" | translate }}
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let element">
          {{ element.lastName || "-" }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="role">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "Role" | translate }}
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let element">
          {{ getRoles(element) || "-" }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="email">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "Email" | translate }}
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let element">
          {{ element.email || "-" }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="phoneNumber">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "Phone Number" | translate }}
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let element">
          {{ element.phoneNumber || "-" }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="umactions">
        <mat-header-cell *matHeaderCellDef>
          {{ "Actions" | translate }}
        </mat-header-cell>
        <mat-cell
          mat-cell
          *matCellDef="let element"
          class="um-action-buttons-gap"
        >
          <button class="um-action-button edit" (click)="onEdit(element)">
            {{ "Edit" | translate }}
          </button>
          <button
            class="um-action-button delete"
            (click)="deleteUser(element.id)"
          >
            {{ "Delete" | translate }}
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <div
      fxLayout="row"
      [ngClass]="
        data.data.length !== 0 ? 'patients-content-paginator-hide' : ''
      "
    >
      <!--    <div fxLayout="col" fxFlex="100" style="margin-top: 3em; justify-content: center;"></div>-->
    </div>
    <mat-paginator
      #paginator
      [pageSizeOptions]="[10, 25, 50, 100]"
      showFirstLastButtons
      [ngClass]="
        data.data.length === 0 ? 'patients-content-paginator-hide' : ''
      "
    />
  </div>
    <div *ngIf="isLoading" fxLayout="row" fxLayoutAlign="center center" class="userManagement-spinner"><nz-spin
      nzSimple></nz-spin>
    </div>
</div>
