import { Component } from '@angular/core';
import { Chart } from 'chart.js/auto';
import { IDashboardChart } from '../../../../interfaces/Dashboard/IDashboardChart';
import { TranslateService } from '@ngx-translate/core';
import { DashboardService } from 'src/app/services/dashboard.service';

@Component({
  selector: 'app-dashboard-admissions-moh-dmsw',
  templateUrl: './dashboard-admissions-moh-dmsw.component.html',
  styleUrl: './dashboard-admissions-moh-dmsw.component.css',
})
export class DashboardAdmissionsMohDmswComponent {
  constructor(
    private readonly dashboardService: DashboardService,
    private translate: TranslateService
  ) {}

  ngAfterViewInit(): void {
    this.getData();
  }

  public mohDmsw: IDashboardChart | null = null;

  PieChart(): void {
    const dataSet = this.mohDmsw?.dataSet?.at(0)?.data;
    const datasets = this.mohDmsw?.dataSet || [];

    const data = {
      labels: this.mohDmsw?.labels,
      datasets: [
        {
          labels: this.translate.instant(this.mohDmsw?.labels || ''),
          data: datasets.map((dataset, index) => dataset.data),
          backgroundColor: datasets.map(
            (dataset, index) => dataset.backgroundColor
          ),
        },
      ],
    };

    new Chart('admissionsMohDmsw', {
      type: 'doughnut',
      data: data,
      options: {
        maintainAspectRatio: false,
        plugins: {
          title: {
            display: true,
            text: this.translate.instant('Admissions from MOH/DMSW'),
          },
        },
      },
    });
  }

  getData() {
    this.dashboardService.getAdmissionsByMoh().subscribe({
      next: (response) => {
        this.mohDmsw = response?.data;
        this.PieChart();
      },
      error: (err) => {},
    });
  }
}
