import { KeycloakConfig } from 'keycloak-js';

const keycloakConfig: KeycloakConfig = {
  realm: 'EHR',
  url: 'https://idp.3ahealth.com/',
  clientId: 'ehr-ui',
};

export const environment = {
  production: false,
  keycloak: keycloakConfig,
  APP_URL: 'https://ehr.3ahealth.com/',
  BACKEND_URL: 'https://ehr-api.3ahealth.com/',
};
