<nz-layout>
  <nz-header class="section-header">
    {{ "Initial Assessment" | translate }}
  </nz-header>
  <nz-content>
    <!-- <app-social-history-questonaire /> -->

    <ng-container *ngFor="let cm of submenu">
      <app-dynamic-questionnaire
        *ngIf="cm.path == componentPaths.socialQuestionnaireAmen"
        [id]="cm.path"
      />
    </ng-container>
  </nz-content>
</nz-layout>
