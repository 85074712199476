import { Component, Input } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ICoding } from 'src/app/interfaces/ICoding';

@Component({
  selector: 'app-identifiers',
  templateUrl: './identifiers.component.html',
  styleUrls: ['./identifiers.component.css'],
})
export class IdentifiersComponent {
  @Input() formSubmitted?: boolean = false;
  @Input() identifiersForm: FormGroup = new FormGroup({});
  @Input() countriesList: ICoding[] = [];

  public idCount = 1;

  public nationalId?: string = '';
  public nationalIdCountry?: string = '';
  public passport?: string = '';
  public passportCountry?: string = '';
  public arc?: string = '';
  public selectedidentifierType: string[] = [];
  constructor(private readonly formBuilder: FormBuilder) {}

  private customValidator(): ValidatorFn {
    return (): ValidationErrors | null => {
      const nationalId = this.identifiersForm.get('nationalId')?.value;
      const nationalIdCountry =
        this.identifiersForm.get('nationalIdCountry')?.value;
      const passport = this.identifiersForm.get('passport')?.value;
      const passportCountry =
        this.identifiersForm.get('passportCountry')?.value;
      const arc = this.identifiersForm.get('arc')?.value;
      const identifierType = this.identifiersForm.get('identifierType')?.value;

      // Ensure at least one identifier is provided
      const atLeastOneFilled =
        (identifierType.includes('nationalId') &&
          nationalId &&
          nationalIdCountry) ||
        (identifierType.includes('passport') && passport && passportCountry) ||
        (identifierType.includes('arc') && arc);

      // Check if each identifier is filled appropriately based on type
      const nationalValid =
        !identifierType.includes('nationalId') ||
        (nationalId && nationalIdCountry) ||
        (!nationalId && !nationalIdCountry);

      const passportValid =
        !identifierType.includes('passport') ||
        (passport && passportCountry) ||
        (!passport && !passportCountry);

      if (!atLeastOneFilled) {
        this.identifiersForm.setErrors({ incorrect: true });
      } else {
        this.identifiersForm.setErrors(null);
      }

      if (!nationalValid) {
        this.identifiersForm.setErrors({ incorrect: true });
      }

      if (!passportValid) {
        this.identifiersForm.setErrors({ incorrect: true });
      }

      return null; // Validation passes
    };
  }

  ngOnInit() {
    this.identifiersForm = this.formBuilder.group({
      identifierType: this.formBuilder.control([]),
      identifierType1: this.formBuilder.control(undefined),
      identifierType2: this.formBuilder.control(undefined),
      identifierType3: this.formBuilder.control(undefined),
      nationalId: this.formBuilder.control(undefined),
      nationalIdCountry: this.formBuilder.control(undefined),
      passport: this.formBuilder.control(undefined),
      passportCountry: this.formBuilder.control(undefined),
      arc: this.formBuilder.control(undefined),
      email: this.formBuilder.control(undefined, [
        Validators.required,
        Validators.email,
      ]),
    });

    this.identifiersForm.setValidators(this.customValidator());

    // Update the selectedidentifierType when the form value changes
    this.identifiersForm
      .get('identifierType')
      ?.valueChanges.subscribe((value) => {
        this.selectedidentifierType = value;
        this.identifiersForm.updateValueAndValidity(); // Re-run validators on change
      });

    this.identifiersForm.updateValueAndValidity();
  }

  addID() {
    this.idCount = this.idCount + 1;
  }

  removeIdentifier(dropdown: number) {
    const type = this.identifiersForm.get(
      dropdown == 1
        ? 'identifierType1'
        : dropdown == 2
        ? 'identifierType2'
        : 'identifierType3'
    )?.value;

    this.identifiersForm
      .get(
        dropdown == 1
          ? 'identifierType1'
          : dropdown == 2
          ? 'identifierType2'
          : 'identifierType3'
      )
      ?.setValue(undefined);

    this.identifiersForm
      .get(
        dropdown == 1
          ? 'identifierType1'
          : dropdown == 2
          ? 'identifierType2'
          : 'identifierType3'
      )
      ?.setValue(undefined);

    if (type == 'nationalId') {
      this.identifiersForm.get('nationalId')?.setValue(undefined);
      this.identifiersForm.get('nationalIdCountry')?.setValue(undefined);
    } else if (type == 'passport') {
      this.identifiersForm.get('passport')?.setValue(undefined);
      this.identifiersForm.get('passportCountry')?.setValue(undefined);
    } else if (type == 'arc') {
      this.identifiersForm.get('arc')?.setValue(undefined);
    }

    this.idCount > 1 ? (this.idCount = this.idCount - 1) : null;
  }

  // Prevent the default behavior of Enter key
  onEnterPress(event: any) {
    event.preventDefault();
  }
}
